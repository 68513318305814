import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import AOS from "aos";
import "aos/dist/aos.css";
import { Dropdown } from "semantic-ui-react";
import "remixicon/fonts/remixicon.css";
import env from "./envconfig";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import {
  useWeb3Modal,
  useDisconnect,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import axios from "axios";


import { toBigInt } from "ethers";

import {
  BrowserProvider,
  Contract,
  formatUnits,
  JsonRpcProvider,
} from "ethers";
import Web3 from "web3";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import WETHbalanceABI from "./WethABI.json"; //mainnet

import USDTbalanceABI from "./USDCABI.json"; //mainnet
// import USDTbalanceABI from "./CainLink.json"; //Testnet
import TokenABI from "./TokenABI.json";
import SwapABI from "./Swap.json";
import { ethers } from "ethers";

import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Big from "big.js";

const { Token, CurrencyAmount, TradeType } = require('@uniswap/sdk-core');
const { Pool, Route, Trade } = require('@uniswap/v3-sdk');



const provider = new ethers.JsonRpcProvider('https://mainnet.base.org');


const zIndex1 = 2000000;
const drawerWidth = 240;
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   title: {
//     flexGrow: 1,
//   },
//   appBarItems: {
//     [theme.breakpoints.down("sm")]: {
//       display: "none",
//     },
//   },
//   mobileMenuButton: {
//     marginRight: theme.spacing(2),
//     [theme.breakpoints.up("sm")]: {
//       display: "none",
//     },
//   },
//   drawerIcon: {
//     display: "none",
//     [theme.breakpoints.down("sm")]: {
//       display: "block",
//     },
//   },
//   drawer: {
//     width: 300,
//   },
// }));

AOS.init();

const Landing = () => {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const projectId = "0b8444fa79de9a3821c5410564de263f";

  const mainnet = {
    chainId: 8453,
    name: "Base LlamaNodes",
    currency: "Base",
    explorerUrl: "https://basescan.org",
    rpcUrl: "https://base.llamarpc.com",

    // chainId: 84532, //testnet
    // explorerUrl: "https://sepolia.basescan.org/", //testnet
    // rpcUrl: "https://sepolia.base.org", //testnet
    // name: "Base Sepolia", //testnet
    // currency: "BSepolia", //testnet
  };

  const chains = [mainnet];
  const metadata = {
    name: "Web3Modal",
    description: "Web3Modal Solana Example",
    url: "https://web3modal.com",
    icons: ["https://avatars.mywebsite.com/"],
  };
  const ethersConfig = defaultConfig({
    metadata,
    chains,
    projectId,
    coinbasePreference: "eoaOnly",
    auth: {
      email: false, // default to true
      // socials: ['google', 'x', 'github', 'discord', 'apple'],
      showWallets: true, // default to true
      walletFeatures: true, // default to true
    },
  });
  createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
    includeWalletIds: [
      "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
      "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
    ],
  });
  //DRAWER ================================
  const navigateToSection = (sectionId) => {
    setOpenDrawer(false);
    setTimeout(() => {
      const section = document.querySelector(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 200);
  };

  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { walletProvider } = useWeb3ModalProvider();
  const { chainId, isConnected } = useWeb3ModalAccount();
  const [address, setaddress] = useState("");
  const [bnbAmount, setBnbAmount] = useState("");
  const [allCurrency, setAllCurrency] = useState([]);
  const [USDTBalance, setUSDTBalance, USDTBalanceref] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState("ETH");
  const [tokenBalance, setTokenBalance, tokenBalanceref] = useState(0);
  const [UsdcBalance, setUsdcBalance, UsdcBalanceref] = useState(0);
  const [WethBalance, setWethBalance, WethBalanceref] = useState(0);

  const [scknAmount, setscknAmount] = useState(0);
  const [usdtPrice, setusdtPrice] = useState(0);
  const [BNBprice, setBNBprice] = useState(0);
  const [tokenPrice, settokenPrice] = useState(0);

  const [offset, setOffset] = useState(0);
  const buyChickenRef = useRef(null);

  const handleWalletConnect = async () => {
    await open();
  };

  const classes1 = useStyles1();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  useEffect(() => {
    AOS.init();

    // Function to handle AOS attributes removal
    const handleAOS = () => {
      if (window.innerWidth < 600) {
        document.querySelectorAll("[data-aos]").forEach((el) => {
          el.setAttribute("data-aos", "");
          el.setAttribute("data-aos-delay", "");
          el.setAttribute("data-aos-duration", "");
        });
      } else {
        document.querySelectorAll(".aos-element").forEach((el) => {
          el.setAttribute("data-aos", "fade-right");
          el.setAttribute("data-aos-delay", "900");
        });
        AOS.refresh();
      }
    };

    // Run the function on initial load
    handleAOS();

    // Add event listener for window resize
    window.addEventListener("resize", handleAOS);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleAOS);
    };
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getbalanceaddress = async () => {
    try {
      Loading.pulse("Connect with Metamask network.... ", {
        messageColor: "red",
        svgColor: "#fdf501",
        svgSize: "100px",
        backgroundColor: "rgb(0 0 0 / 87%)",
      });
      try {
        if (!isConnected) throw Error("User disconnected");

        //console.log("inside");

        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        //console.log("address", address);
        setaddress(address);
        //console.log(address, "address");

        localStorage.setItem("userAddress", address);
        toast.success("Wallet Connected!");

        FetchwalletUSDTbalance(address);
        FetchTokenbalance(address);
        FetchUSDCbalance(address);
        FetchWETHbalance(address);
        initializeWeb3();
        Loading.remove();
      } catch (error) {
        console.error(error);
        Loading.remove();
      }
    } catch (err) {
      Loading.remove();
    }
  };

  async function FetchTokenbalance(data) {
    if (data) {
      const bnbProvider = new JsonRpcProvider(env.rpc_url);

      const Tokencontarct = new Contract(
        env.tokenAddres,
        TokenABI,
        bnbProvider
      );
      const tokenBalance = await Tokencontarct.balanceOf(data);
      localStorage.setItem("scknBalance", Number(tokenBalance) / 1000000);
      setTokenBalance(Number(tokenBalance) / 1000000);

      //console.log(tokenBalance, "tokenBalanceref", tokenBalanceref.current);
    } else {
      //console.log("invaid addeesssssjjdnnd");
    }
  }

  async function FetchwalletUSDTbalance(data) {
    const ethBalance1 = localStorage.getItem("Balance");
    setUSDTBalance(ethBalance1);

    if (data) {
      if (walletProvider != null) {
        const provider = new BrowserProvider(walletProvider);

        const balance = await provider.getBalance(data);

        const ethBalance = parseInt(balance) / 1000000000000000000;
        localStorage.setItem("Balance", ethBalance);
        setUSDTBalance(ethBalance);

        // console.log(USDTBalance, "USDTBalanceref", USDTBalanceref.current);
      }
    } else {
      //console.log("invaid addeesssssjjdnnd");
    }
  }

  async function FetchUSDCbalance(data) {
    if (data) {
      const bnbProvider = new JsonRpcProvider(env.rpc_url);

      const Tokencontarct = new Contract(
        env.usdtAddres,
        USDTbalanceABI,
        bnbProvider
      );
      const tokenBalance = await Tokencontarct.balanceOf(data);
      localStorage.setItem(
        "USDTBalance",
        Number(tokenBalance) / 1000000000000000000
      );
      setUsdcBalance(Number(tokenBalance) / 1000000000000000000);

      //console.log(tokenBalance, "tokenBalanceref", tokenBalanceref.current);
    } else {
      //console.log("invaid addeesssssjjdnnd");
    }
  }

  async function FetchWETHbalance(data) {
    if (data) {
      const bnbProvider = new JsonRpcProvider(env.rpc_url);

      const Tokencontarct = new Contract(
        env.wethAddres,
        WETHbalanceABI,
        bnbProvider
      );
      const tokenBalance = await Tokencontarct.balanceOf(data);
      localStorage.setItem(
        "WETHBalance",
        Number(tokenBalance) / 1000000000000000000
      );
      setWethBalance(Number(tokenBalance) / 1000000000000000000);

      //console.log(tokenBalance, "tokenBalanceref", tokenBalanceref.current);
    } else {
      //console.log("invaid addeesssssjjdnnd");
    }
  }

  if (isConnected) {
    const Useradd = localStorage.getItem("userAddress");
    if (Useradd == null) {
      getbalanceaddress();
    } else {
    }
  } else {
  }

  async function disconnectWallet() {
    Loading.pulse("Disconnecting...", {
      messageColor: "#fdf501",
      svgColor: "#fdf501",
      svgSize: "100px",
      backgroundColor: "rgb(0 0 0 / 87%)",
    });
    localStorage.clear();
    const provider = new BrowserProvider(walletProvider);
    // console.log("Provider:", provider);
    // console.log("Provider emit method:", provider?.emit);
    try {
      if (provider && typeof provider?.emit === "function") {
        await disconnect();
        toast.success("Wallet Disconnected!");
      } else {
        //console.error("Provider or emit function is not available.");
      }
    } catch (error) {
      //console.error("Error occurred while disconnecting:", error);
    }
    setaddress("");
    setAnchorEl(null);
    initializeWeb3();
    Loading.remove();
  }

  async function initializeWeb3() {
    try {
      const userAddress = localStorage.getItem("userAddress");
      setaddress(userAddress);

      if (userAddress == null) {
        setTokenBalance(0);
        setUSDTBalance(0);
        setUsdcBalance(0);
        setWethBalance(0);

        setaddress("");
      } else {
        //console.log(userAddress);

        FetchTokenbalance(userAddress);
        FetchUSDCbalance(userAddress);
        FetchWETHbalance(userAddress);

        FetchwalletUSDTbalance(userAddress);
      }
    } catch (error) {
      //console.error("Error occurred while disconnecting:", error);
    }
  }

  async function getPrice() {
    try {

  
      // Infura/Alchemy RPC URL for Base network (replace with your own provider)
      const RPC_URL = "https://mainnet.base.org";  // Replace with appropriate RPC for Base

      // Uniswap V3 pool contract ABI (minimal ABI to fetch slot0 data)
      const POOL_ABI = [
        "function slot0() external view returns (uint160 sqrtPriceX96, int24 tick, uint16 observationIndex, uint16 observationCardinality, uint16 observationCardinalityNext, uint8 feeProtocol, bool unlocked)"
      ];

      // Replace with your Uniswap V3 pool address
      const POOL_ADDRESS = "0xf9ca1f02fbb9A81a3325b395cFC575BA99308216";  // Insert Uniswap V3 pool address

      // Token decimals (for ETH/USDC example)
      const TOKEN0_DECIMALS = 18;  // Example: ETH
      const TOKEN1_DECIMALS = 6;   // Example: USDC

      // Connect to blockchain provider
      const provider = new ethers.JsonRpcProvider(RPC_URL);

       // Connect to the Uniswap V3 pool contract
      const poolContract = new ethers.Contract(POOL_ADDRESS, POOL_ABI, provider);

      // Fetch slot0 data (sqrtPriceX96 and other details)
      const slot0 = await poolContract.slot0();
      const sqrtPriceX96 = toBigInt(slot0[0]);

      console.log("Raw sqrtPriceX96:", sqrtPriceX96.toString());

      // Q96 constant for calculations (2^96)
      const Q96 = toBigInt("79228162514264337593543950336");

      // Convert sqrtPriceX96 to float ratio
      const priceRatio = Number(sqrtPriceX96) / Number(Q96);

      // Get the price by squaring the ratio
      let price = priceRatio ** 2;

      // Adjust for token decimals
      price = price * 10 ** (TOKEN0_DECIMALS - TOKEN1_DECIMALS);

      console.log(`Token0 price in terms of Token1: ${price.toFixed(6)}`);


    } catch (error) {
      console.log("price error===",error)
    }
   
}


async function getTokenPrice() {

  
// // Uniswap V3 pool address for ETH/SCKN
// const poolAddress = "0xf9ca1f02fbb9A81a3325b395cFC575BA99308216";

// // Uniswap V3 pool contract ABI (only necessary functions)
// const poolABI = [
//   "function slot0() external view returns (uint160 sqrtPriceX96, int24 tick, uint16 observationIndex, uint16 observationCardinality, uint16 observationCardinalityNext, uint8 feeProtocol, bool unlocked)",
//   "function token0() external view returns (address)",
//   "function token1() external view returns (address)"
// ];

// const poolContract = new ethers.Contract(poolAddress, poolABI, provider);
//      // Get token0 and token1 addresses
//      const token0 = await poolContract.token0();
//      const token1 = await poolContract.token1();
 
//      console.log("Token0:", token0);
//      console.log("Token1:", token1);
 
//      // Address for WETH (Wrapped Ether) on Ethereum mainnet
//      const WETH_ADDRESS = "0x4200000000000000000000000000000000000006";
 
//      let isEthToken0 = token0.toLowerCase() === WETH_ADDRESS.toLowerCase();
 
//      // Fetch pool slot0 data (which contains sqrtPriceX96)
//      const slot0 = await poolContract.slot0();
//      const sqrtPriceX96 = Big(slot0.sqrtPriceX96.toString());
 
//      console.log("sqrtPriceX96:", sqrtPriceX96.toString());
 
//      // Calculate price ratio: (sqrtPriceX96 / 2^96)^2
//      const factor = Big(2).pow(96);
//      const priceRatio = sqrtPriceX96.div(factor).pow(2);
 
//      // If ETH is token0, priceRatio gives SCKN price in ETH, otherwise invert it
//      const finalPrice = isEthToken0 ? priceRatio : Big(1).div(priceRatio);
 
//      console.log(`SCKN/ETH Price: ${finalPrice.toString()}`);


// Base chain provider (Alchemy/Infura/Public RPC)
const provider = new ethers.JsonRpcProvider("https://mainnet.base.org");

const poolAddress = "0xf9ca1f02fbb9a81a3325b395cfc575ba99308216";

// Uniswap V3 Pool ABI (only slot0 needed to get price)
const poolABI = [
  "function slot0() external view returns (uint160, int24, uint16, uint16, uint16, uint8, bool)"
];


const poolContract = new ethers.Contract(poolAddress, poolABI, provider);
  const [sqrtPriceX96] = await poolContract.slot0();

  const tokenDecimals = 6; // Update this according to token details

  // Calculate price based on Uniswap formula
  const price = (Number(sqrtPriceX96) ** 2) / (2 ** (96 * 2));
  const formattedPrice = price / 10 ** tokenDecimals;

  console.log(`Token Price in ETH: ${formattedPrice}`);

}

  useEffect(() => {
    initializeWeb3();

    const SCKN = env.tokenAddres; // USDC Address
    const WETH = env.wethAddres; // WETH Address
    getTokenPrice();

  }, [bnbAmount]);

  useEffect(() => {
    const handleScroll = () => {
      if (buyChickenRef.current) {
        const sectionTop = buyChickenRef.current.offsetTop;
        const sectionHeight = buyChickenRef.current.offsetHeight;
        const pageOffset = window.pageYOffset;

        if (
          pageOffset >= sectionTop &&
          pageOffset <= sectionTop + sectionHeight
        ) {
          setOffset(pageOffset - sectionTop);
        } else {
          setOffset(0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const formatter_interest = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 8,
    maximumFractionDigits: 8,
  });

  function number_format(num, precision = null) {
    let decimalPlaces = precision !== null ? precision : Math.abs(Math.floor(Math.log10(Math.abs(num)))) + 2;
    return num.toFixed(decimalPlaces);
  }

  useEffect(() => {
    // const getUsdtPrice = async () => {
    //   Loading.pulse("Processing...", {
    //     messageColor: "#fdf501",
    //     svgColor: "#fdf501",
    //     svgSize: "100px",
    //     backgroundColor: "rgb(0 0 0 / 87%)",
    //   });
    //   const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=ETH&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
    //   const apiUrl1 = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=USDC&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
    //   const apiUrl2 = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=ETH&tsyms=USDT&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;

    //   const response = await axios.get(apiUrl);
    //   const response1 = await axios.get(apiUrl1);
    //   const response2 = await axios.get(apiUrl2);

    //   if (
    //     response.data &&
    //     response.data.ETH &&
    //     response1.data &&
    //     response1.data.USDC &&
    //     response2.data &&
    //     response2.data.ETH
    //   ) {
    //     console.log(response, response1, response2);

    //     const value = response.data.ETH.USDT;
    //     const value1 = response1.data.USDC.USDT;
    //     const value2 = response2.data.ETH.USDT;

    //     let unitCalculation;

    //     if (selectedCurrency === "ETH") {
    //       unitCalculation = (value * 1) / +env.TTTusdtprice;
    //       setBNBprice(value.toFixed(4));
    //       setusdtPrice(unitCalculation.toFixed(4));
    //     } else if (selectedCurrency === "WETH") {
    //       unitCalculation = (value2 * 1) / +env.TTTusdtprice;
    //       setBNBprice(value2.toFixed(4));
    //       setusdtPrice(unitCalculation.toFixed(4));
    //     } else {
    //       unitCalculation = (value1 * 1) / +env.TTTusdtprice;
    //       setBNBprice(value1.toFixed(4));
    //       setusdtPrice(unitCalculation.toFixed(4));
    //     }
    //   }
    //   Loading.remove();
    // };


    const getUsdtPrice = async () => {
      Loading.pulse("Processing...", {
        messageColor: "#fdf501",
        svgColor: "#fdf501",
        svgSize: "100px",
        backgroundColor: "rgb(0 0 0 / 87%)",
      });
     
      const options = {
        method: 'GET',
        url: `https://public-api.dextools.io/trial//v2/pool/base/${env.poolAddress}/price`,
        headers : {
          "X-API-KEY": env.apiKey
        }
      };
      const  response  = await axios.request(options);

      console.log("price from dextools ",response);

      if (response.data != null) {

        let response_value = response.data.data;

        console.log("response_value ",response_value);

        if(response_value != null)
        {
          const value = response_value.priceChain;
          const value1 = response_value.price;
          let eth_price = number_format(value,14);
          let usd_price =number_format(value1,10);

          console.log("eth_price",eth_price);
          console.log("usd_price",usd_price);
  
          let unitCalculation;
          settokenPrice(usd_price);
          if (selectedCurrency === "ETH" || selectedCurrency === "WETH") {
            unitCalculation = 1 / +eth_price;
            setusdtPrice(unitCalculation.toFixed(4));
          } 
          else {
            unitCalculation = 1 / +usd_price;
            setusdtPrice(unitCalculation.toFixed(4));
          }

        }

      
      }
      Loading.remove();
    };

    getUsdtPrice();
  }, [selectedCurrency]);

  useEffect(() => {
    const calculatedTttAmount = bnbAmount * usdtPrice;
    setscknAmount(calculatedTttAmount);
  }, [bnbAmount, usdtPrice]);

  const handleBuy = async () => {
    Loading.pulse("Processing...", {
      messageColor: "#fdf501",
      svgColor: "#fdf501",
      svgSize: "100px",
      backgroundColor: "rgb(0 0 0 / 87%)",
    });

    console.log(selectedCurrency, "selectedCurrency");

    try {
      let Buy;

      if (scknAmount >= 1) {
        const bnbProvider = new JsonRpcProvider(env.rpc_url);

        const provider = new BrowserProvider(walletProvider);

        const signer = await provider.getSigner();

        // console.log(signer, "signers");

        const tokenContract_usdt = new Contract(
          env.usdtAddres,
          USDTbalanceABI,
          signer
        );

        const tokenContract_WETH = new Contract(
          env.wethAddres,
          WETHbalanceABI,
          signer
        );

        const swapContract1 = new Contract(env.BuyAddress, SwapABI, signer);

        if (selectedCurrency == "ETH") {
          if (
            USDTBalanceref.current < 0 ||
            bnbAmount > USDTBalanceref.current
          ) {
            toast.error("Insufficient ETH Balance");
            Loading.remove();
          } else {
            const weiValue = ethers.parseUnits(bnbAmount.toString(), 18);

            const receiveamount = ethers.parseUnits(
              scknAmount.toFixed(2).toString(),
              6
            );

            const gasPrice = (await provider.getFeeData()).gasPrice;

            // console.log(gasPrice, "jbcjbdcjjkcsbjdcsbcdbkj");

            try {
              Buy = await swapContract1.buyToken(
                address,
                receiveamount,
                weiValue,
                env.tokenAddres,
                {
                  value: weiValue,
                }
              );

              //console.log(Buy, "Buy");

              FetchTokenbalance(address);
              FetchUSDCbalance(address);
              FetchWETHbalance(address);

              FetchwalletUSDTbalance(address);

              if (Buy) {
                //console.log("Token purchased successfully");
                toast.success("Token Purcashed Sucessfully");
                window.location.reload();
                initializeWeb3();
                setBnbAmount("");
                Loading.remove();
              }
            } catch (error) {
              // console.log("error", error);
              Loading.remove();
              FetchTokenbalance(address);
              FetchUSDCbalance(address);
              FetchWETHbalance(address);
              FetchwalletUSDTbalance(address);
              toast.error("Transaction failed");
            }
          }
        } else if (selectedCurrency == "WETH") {
          if (
            WethBalanceref.current < 0 ||
            bnbAmount > WethBalanceref.current
          ) {
            toast.error("Insufficient WETH Balance");
            Loading.remove();
          } else {
            const weiValue = ethers.parseUnits(bnbAmount.toString(), 18);

            const receiveamount = ethers.parseUnits(
              scknAmount.toFixed(2).toString(),
              6
            );

            const gasPrice = (await provider.getFeeData()).gasPrice;

            var Approved = await tokenContract_WETH.methods
              .approve(env.BuyAddress, weiValue)
              .send({ from: address });

            if (Approved) {
              try {
                Buy = await swapContract1.buyToken(
                  address,
                  receiveamount,
                  weiValue,
                  env.tokenAddres,
                  {
                    value: weiValue,
                  }
                );

                FetchTokenbalance(address);
                FetchUSDCbalance(address);
                FetchWETHbalance(address);
                FetchwalletUSDTbalance(address);

                if (Buy) {
                  toast.success("Token Purcashed Sucessfully");
                  window.location.reload();
                  initializeWeb3();
                  setBnbAmount("");
                  Loading.remove();
                }
              } catch (error) {
                // console.log("error", error);
                Loading.remove();
                FetchTokenbalance(address);
                FetchUSDCbalance(address);
                FetchWETHbalance(address);
                FetchwalletUSDTbalance(address);
                toast.error("Transaction failed");
              }
            } else {
              Loading.remove();
            }
          }
        } else {
          if (
            UsdcBalanceref.current < 0 ||
            bnbAmount > UsdcBalanceref.current
          ) {
            toast.error("Insufficient USDC Balance");
            Loading.remove();
          } else {
            const weiValue = ethers.parseUnits(bnbAmount.toString(), 18);

            const receiveamount = ethers.parseUnits(
              scknAmount.toFixed(2).toString(),
              6
            );

            const gasPrice = (await provider.getFeeData()).gasPrice;

            var Approved = await tokenContract_usdt.methods
              .approve(env.BuyAddress, weiValue)
              .send({ from: address });

            if (Approved) {
              try {
                Buy = await swapContract1.buyToken(
                  address,
                  receiveamount,
                  weiValue,
                  env.tokenAddres,
                  {
                    value: weiValue,
                  }
                );

                //console.log(Buy, "Buy");

                FetchTokenbalance(address);
                FetchUSDCbalance(address);
                FetchWETHbalance(address);

                FetchwalletUSDTbalance(address);

                if (Buy) {
                  //console.log("Token purchased successfully");
                  toast.success("Token Purcashed Sucessfully");
                  window.location.reload();
                  initializeWeb3();
                  setBnbAmount("");
                  Loading.remove();
                }
              } catch (error) {
                // console.log("error", error);
                Loading.remove();
                FetchTokenbalance(address);
                FetchUSDCbalance(address);
                FetchWETHbalance(address);

                FetchwalletUSDTbalance(address);
                toast.error("Transaction failed");
              }
            } else {
              Loading.remove();
            }
          }
        }
      } else {
        Loading.remove();

        toast.error("Buy Minimum 1 TTT Token");
      }
    } catch (error) {
      toast.error("Transaction failed");
      FetchTokenbalance(address);
      FetchUSDCbalance(address);
      FetchWETHbalance(address);

      FetchwalletUSDTbalance(address);
      //console.error("Error executing transaction:", error);
    }
  };

  const selectToken = (value) => {
    setSelectedCurrency(value);
  };

  useEffect(() => {
    const currArrayCrypto = [
      {
        value: "ETH",
        key: "ETH",
        text: "ETH",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664015323/fswpx9cb8ygezbx25edq.png",
        },
      },
      {
        value: "USDC",
        key: "USDC",
        text: "USDC",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014174/t4ayejcmp5be42sm1o7k.png",
        },
      },
      {
        value: "WETH",
        key: "WETH",
        text: "WETH",
        image: {
          avatar: true,
          src: "https://res.cloudinary.com/dxknk0rio/image/upload/v1722863778/2396_rdxgd0.png",
        },
      },
    ];
    setAllCurrency(currArrayCrypto);
    setSelectedCurrency(currArrayCrypto[0].value);
  }, [0]);

  return (
    <>
      <main className="meme_coin_main_page">
        <React.Fragment>
          <section className="chicken_nav_bar">
            <Link className="nav_chicken_items" to="/">
              <div className="chicken_nav_logo">
                <img src={require("../assets/chicken_logo.png")} />
              </div>
            </Link>
            {isMatch ? (
              <>
                <React.Fragment className="chicken_drawer">
                  <Drawer
                    anchor="left"
                    open={openDrawer}
                    sx={{
                      display: { xs: "block", sm: "block" },
                      "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                        zIndex: zIndex1,
                      },
                    }}
                    onClose={() => setOpenDrawer(false)}
                  >
                    <List>
                      <>
                        <ListItemButton>
                          {" "}
                          <ListItemIcon>
                            <ListItemText>
                              <Link className="nav_brand " to="/">
                                {" "}
                                <img
                                  src={require("../assets/chicken_logo.png")}
                                  className="blackshow"
                                />
                              </Link>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>
                        <ListItemButton>
                          <ListItemIcon>
                            <ListItemText>
                              <span
                                className="text-dark text-white"
                                onClick={() =>
                                  navigateToSection("#chicken_about_section")
                                }
                              >
                                About
                              </span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>{" "}
                        <ListItemButton>
                          <ListItemIcon>
                            <ListItemText>
                              <span
                                className="text-dark text-white"
                                onClick={() =>
                                  navigateToSection("#buy_chicken_section")
                                }
                              >
                                Buy SCKN
                              </span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>{" "}
                        <ListItemButton>
                          <ListItemIcon>
                            <ListItemText>
                              <span
                                className="text-dark text-white"
                                onClick={() =>
                                  navigateToSection(
                                    "#chicken_tokenomics_section"
                                  )
                                }
                              >
                                Tokenomics
                              </span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>{" "}
                        <ListItemButton>
                          <ListItemIcon>
                            <ListItemText>
                              <span
                                className="text-dark text-white"
                                onClick={() =>
                                  navigateToSection("#chicken_charity_section")
                                }
                              >
                                Charity
                              </span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>{" "}
                        <ListItemButton>
                          <ListItemIcon>
                            <ListItemText>
                              <span
                                className="text-dark text-white"
                                onClick={() =>
                                  navigateToSection(
                                    "#chicken_community_section"
                                  )
                                }
                              >
                                Community
                              </span>
                            </ListItemText>
                          </ListItemIcon>
                        </ListItemButton>{" "}
                        <ListItemButton>
                          <ListItemIcon>
                            <ListItemText
                              onClick={() => setOpenDrawer(!openDrawer)}
                            >
                              {address === "" ? (
                                <button
                                  onClick={handleWalletConnect}
                                  className="drawer_connect_btn"
                                >
                                  {" "}
                                  <i class="ri-wallet-fill"></i> Connect
                                </button>
                              ) : (
                                <>
                                  <button
                                    // aria-controls="simple-menu"
                                    // aria-haspopup="true"
                                    className=" text-white drawer_connect_btn"
                                    onClick={disconnectWallet}
                                  >
                                    {" "}
                                    <i class="ri-wallet-fill"></i>{" "}
                                    {address.substring(0, 5)} ...
                                  </button>
                                  {/* <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                  >
                                    <MenuItem onClick={disconnectWallet}>
                                      <LogoutIcon />{" "}
                                      <span className="mx-3">Disconnect</span>{" "}
                                    </MenuItem>
                                  </Menu> */}
                                </>
                              )}
                            </ListItemText>
                          </ListItemIcon>

                          {/* <ListItemIcon>
                            <ListItemText
                              onClick={() => setOpenDrawer(!openDrawer)}
                            >
                              {address === "" ? (
                                <button
                                  onClick={handleWalletConnect}
                                  className="drawer_connect_btn"
                                >
                                  {" "}
                                  <i class="ri-wallet-fill"></i> Connect
                                </button>
                              ) : (
                                <>
                                  <button
                                    // aria-controls="simple-menu"
                                    // aria-haspopup="true"
                                    className="dropdown-toggle text-white drawer_connect_btn"
                                    onClick={handleClick}
                                  >
                                    {" "}
                                    <i class="ri-wallet-fill"></i>{" "}
                                    {address.substring(0, 2)} ...
                                  </button>
                                  <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                  >
                                    <MenuItem onClick={disconnectWallet}>
                                      <LogoutIcon />{" "}
                                      <span className="mx-3">Disconnect</span>{" "}
                                    </MenuItem>
                                  </Menu>
                                </>
                              )}
                            </ListItemText>
                          </ListItemIcon> */}
                        </ListItemButton>{" "}
                      </>
                    </List>
                  </Drawer>

                  <> </>
                  <IconButton
                    sx={{ color: "white", marginLeft: "auto" }}
                    onClick={() => setOpenDrawer(!openDrawer)}
                  >
                    <MenuIcon color="white" />
                  </IconButton>
                </React.Fragment>
              </>
            ) : (
              <>
                <div className="chicken_nav_items">
                  <ul>
                    <a href="#chicken_about_section">
                      {" "}
                      <li>About</li>
                    </a>
                    <a href="#buy_chicken_section">
                      <li>Buy SCKN</li>
                    </a>
                    <a href="#chicken_tokenomics_section">
                      {" "}
                      <li>Tokenomics</li>
                    </a>
                    <a href="#chicken_charity_section">
                      {" "}
                      <li>Charity</li>
                    </a>
                    <a href="#chicken_community_section">
                      {" "}
                      <li>Community</li>
                    </a>

                    {address === "" ? (
                      <button onClick={handleWalletConnect}>
                        {" "}
                        <i class="ri-wallet-fill"></i> Connect
                      </button>
                    ) : (
                      <>
                        <button
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          className="dropdown-toggle text-white"
                          onClick={handleClick}
                        >
                          {" "}
                          <i class="ri-wallet-fill"></i>{" "}
                          {address.substring(0, 5)} ...
                        </button>

                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={disconnectWallet}>
                            <LogoutIcon />{" "}
                            <span className="mx-3">Disconnect</span>{" "}
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </ul>
                </div>
              </>
            )}
          </section>
        </React.Fragment>
        <section className="hero_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 meme_hero_content">
                <div className="hero_section_content">
                  <h1>$SCKN</h1>
                  <p>
                    Paying homage to the most iconic chicken
                    <br /> on the Internet. Screaming it's way into
                    <br /> viral videos and now into the
                    <br />
                    meme coin world
                  </p>
                  <button>
                    {" "}
                    <a href="#buy_chicken_section">Buy SCKN</a>{" "}
                  </button>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="hero_section_banner">
                  <img
                    src={require("../assets/Hero_banner.webp")}
                    className="d-block mx-auto"
                    width="20%"
                  />
                  <img
                    src={require("../assets/Bases-two.png")}
                    className="d-block mx-auto mt-3 "
                    width="50%"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="chicken_effect" id="chicken_about_section">
          <img src={require("../assets/chicken_start_effect.webp")} />
          <img
            src={require("../assets/effect-3.webp")}
            data-aos="fade-right"
            className="effect chicken_hhh"
          />
        </div>

        <div ref={buyChickenRef}>
          <section className="chicken_about_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="chicken_about_banner">
                    <img src={require("../assets/chicken_about_banner.gif")} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="chicken_about_content">
                    <h2 id="about">About</h2>
                    <p>
                      Screaming Chicken has spent the last decade broadcasting
                      that scream across the Internet and is now ready to spread
                      its wings into crypto. Cats, dogs and frogs have paved the
                      way for this memerable chicken to prove itself during our
                      meme era
                    </p>
                    <p>
                      Screaming Chicken has been carefully created and fairly
                      launched on @base network. LP Locked and 100% of available
                      tokens are out there for holders to peck up. Simple and
                      easy memenomics to benefit all users
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="buy_chicken_section">
            <h1
              className="parallax-text left-to-right d-flex"
              style={{ transform: `translateX(${offset * 0.5}px)` }}
            >
              SCKN
              <img src={require("../../src/assets/sckn_slide_asset.webp")} />
              <span className="basex">
                BASE
                <img src={require("../../src/assets/base_slide_asset.webp")} />
              </span>
              SCKN
              <img src={require("../../src/assets/sckn_slide_asset.webp")} />
              <span className="basex">
                BASE
                <img src={require("../../src/assets/base_slide_asset.webp")} />
              </span>
              SCKN
              <img src={require("../../src/assets/sckn_slide_asset.webp")} />
              <span className="basex">
                BASE
                <img src={require("../../src/assets/base_slide_asset.webp")} />
              </span>
              SCKN
              <img src={require("../../src/assets/sckn_slide_asset.webp")} />
              <span className="basex">
                BASE
                <img src={require("../../src/assets/base_slide_asset.webp")} />
              </span>
            </h1>
            <h1
              className="parallax-text right-to-left d-flex"
              style={{ transform: `translateX(${-offset * 0.5}px)` }}
              id="buy_chicken_section"
            >
              SCKN
              <img src={require("../../src/assets/sckn_slide_asset.webp")} />
              <span className="basex">
                BASE{" "}
                <img src={require("../../src/assets/base_slide_asset.webp")} />
              </span>
              SCKN
              <img src={require("../../src/assets/sckn_slide_asset.webp")} />
              <span className="basex">
                BASE{" "}
                <img src={require("../../src/assets/base_slide_asset.webp")} />
              </span>
              SCKN
              <img src={require("../../src/assets/sckn_slide_asset.webp")} />
              <span className="basex">
                BASE{" "}
                <img src={require("../../src/assets/base_slide_asset.webp")} />
              </span>
              SCKN
              <img src={require("../../src/assets/sckn_slide_asset.webp")} />
              <span className="basex">
                BASE{" "}
                <img src={require("../../src/assets/base_slide_asset.webp")} />
              </span>
            </h1>
            <div className="row">
              <div className="col-lg-6">
                <div className="buy_chicken_banner">
                  <img src={require("../assets/buy_sckn_banner.webp")} />
                </div>
              </div>
              <div className="col-lg-6 d-flex yours_buy_col align-items-center justify-content-center">
                <div className="buy_sckn_card">
                  <div className="your_buy_sckn">
                    <div className="">
                      <p>You Pay</p>
                      <h4>
                        <input
                          type="number"
                          onKeyDown={(evt) =>
                            [
                              "ArrowUp",
                              "ArrowDown",
                              "e",
                              "E",
                              "+",
                              "-",
                            ].includes(evt.key) && evt.preventDefault()
                          }
                          value={bnbAmount}
                          placeholder="Enter Amount"
                          onChange={(e) => {
                            setBnbAmount(e.target.value);
                          }}
                        />
                      </h4>
                      <p>
                        {selectedCurrency === "ETH" ? (
                          <>
                            Balance:{" "}
                            {formatter_interest.format(USDTBalanceref.current)}{" "}
                            ETH
                          </>
                        ) : selectedCurrency === "WETH" ? (
                          <>
                            Balance:{" "}
                            {formatter_interest.format(WethBalanceref.current)}{" "}
                            WETH
                          </>
                        ) : (
                          <>
                            Balance:{" "}
                            {formatter_interest.format(UsdcBalanceref.current)}{" "}
                            USDC
                          </>
                        )}
                      </p>
                    </div>
                    <div>
                      <div className="btn-group deposit_coin_dropdown">
                        <Dropdown
                          fluid
                          value={selectedCurrency}
                          selection
                          options={allCurrency}
                          onChange={(e, { value }) => selectToken(value)}
                        />
                      </div>
                      <p className="bln_text">
                        {" "}
                        {/* ~ $ {(0.001 * scknAmount).toFixed(4)} */}
                        ~ $ {(tokenPrice * scknAmount).toFixed(4)}
                      </p>
                    </div>
                  </div>
                  <div className="down_arrow">
                    <i className="ri-arrow-down-line"></i>
                  </div>
                  <div className="your_buy_sckn receive">
                    <div className="">
                      <p>You Receive</p>
                      <h4>{scknAmount.toFixed(2)}</h4>
                      <p>
                        Balance:{" "}
                        {formatter_interest.format(tokenBalanceref.current)}{" "}
                        SCKN{" "}
                      </p>
                    </div>
                    <div>
                      <div className="btn-group deposit_coin_dropdown">
                        <button className="btn">
                          <div className="deposit_select_coin-dropdown_list">
                            <div className="chicken_buy_dropdown">
                              <img src={require("../assets/drop_down.png")} />
                              <h3 className="m-0">
                                <span className="spn_usdt">SCKN</span>{" "}
                              </h3>
                            </div>
                          </div>
                        </button>
                      </div>
                      <p className="bln_text">
                        {" "}
                        {/* ~ $ {(0.001 * scknAmount).toFixed(4)} */}
                        ~ $ {(tokenPrice * scknAmount).toFixed(4)}
                      </p>
                    </div>
                  </div>
                  {address ? (
                    <button className="buy_sckn_btn" onClick={handleBuy}>
                      Buy SCKN
                    </button>
                  ) : (
                    <button
                      className="buy_sckn_btn"
                      onClick={handleWalletConnect}
                    >
                      Connect wallet
                    </button>
                  )}

                  <div className="bnb_value mt-3">
                    {/* <h3>
                      {" "}
                      1 ETH = {usdtPrice} SCKN ($ {BNBprice})
                    </h3> */}
                    <h3>
                      {" "}
                      1 {selectedCurrency} = {usdtPrice} SCKN (1 SCKN = ${" "}
                      {tokenPrice})
                    </h3>
                    {/* <div className="max_slippage">
                      <p>Max. Slippage</p>
                      <p>0.0%</p>
                    </div>
                    <div className="max_slippage">
                      <p>Max. Slippage</p>
                      <p>0.0%</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section
          className="chicken_tokenomics_section"
          id="chicken_tokenomics_section"
        >
          <div className="container">
            <div className="chicken_tokenomics_title">Tokenomics</div>
            <div className="row">
              <div className="col-lg-6 meme_hero_content">
                <div className="chicken_tokenomics_content">
                  <h4>Total Supply</h4>
                  <h3>96 240 000 000</h3>
                  <div className="tokenomics_taxes_box">
                    <p>NO TAXES, NO NONSENSE</p>
                    <p>LP LOCKED</p>
                    <p>100% TOKEN CIRCULATION,</p>
                    <p>FIXED TOTAL SUPPLY </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 tokenomics-right-logo-wrap">
                <div className="chicken_tokenomics_banner">
                  <img
                    src={require("../assets/token_img1.webp")}
                    className="token_img1"
                  />
                  <img
                    src={require("../assets/token_img2.webp")}
                    className="token_img2"
                  />
                  <a
                    // href="https://basescan.org/address/0x74F6D06141F6E1eDE81bC9047e35E82EdffC666D"
                    href="https://basescan.org/address/0xAeaA09661D0093f9f9feb1c7654703Da506dD67A"
                    target="blank"
                  >
                    {" "}
                    <img
                      src={require("../assets/token_base.webp")}
                      className="token_base"
                    />
                  </a>
                  <a
                    target="blank"
                    href={`https://www.dextools.io/app/en/base/pair-explorer/0xf9ca1f02fbb9a81a3325b395cfc575ba99308216?t=`+new Date().getTime()}
                  >
                    <img
                      src={require("../assets/token_dex.webp")}
                      className="token_dexF"
                    />
                  </a>
                  <a
                    target="blank"
                    href="https://app.uniswap.org/swap?outputCurrency=0xAeaA09661D0093f9f9feb1c7654703Da506dD67A&chain=base"
                  >
                    <img
                      src={require("../assets/uniswap-logo.png")}
                      className="sckn-uniswap"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="chicken_effect">
          <img
            src={require("../assets/effect-2.webp")}
            data-aos="fade-left"
            className="effect"
          />
          <img
            src={require("../assets/chiken_end_effect.webp")}
            className="chicken_end"
          />
        </div>
        <section
          className="chicken_charity_section"
          id="chicken_charity_section"
        >
          <div className="container">
            <div className="row chicken_charity_section_row">
              <div className="col-lg-2">
                <div className="chicken_charity_bottom" data-aos="fade-up">
                  <img src={require("../assets/chicken_charity_bottom.webp")} />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="chicken_charity_card">
                  <h2>Charity</h2>
                  <p>
                    Excess Proceeds raised from $SCKN growth are converted to
                    <br /> fiat & donated to a <span>not-for-profit</span>,
                    volunteer
                    <br /> run animal sanctuary in Australia
                  </p>
                  <div className="char-btn-wrap">
                    <a
                      className="char-btn"
                      href="https://willowtreesanctuary.com.au/"
                      target="_blank"
                    >
                      Charity{" "}
                      <span>
                        <i className="fa-solid fa-arrow-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="chicken_charity_top" data-aos="fade-down">
                  <img src={require("../assets/chicken_charity_top.webp")} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="chicken_effect">
          <img src={require("../assets/chicken_start_effect.webp")} />
          <img
            src={require("../assets/effect-3.webp")}
            data-aos="fade-right"
            className="effect chicken_hhh"
          />
        </div>
        <section
          className="chicken_community_section"
          id="chicken_community_section"
        >
          <div className="container">
            <div className="chicken_community_content">
              <h2>COMMUNITY</h2>
              <div className="community_link">
                <a href="https://x.com/Screaming_base" target="blank">
                  <div className="link_1">
                    <img src={require("../assets/twitter_x.webp")} />
                    <h3>Follow Us</h3>
                  </div>
                </a>
                <div className="chicken_community_asset">
                  <img src={require("../assets/community_chicken.webp")} />
                  <img
                    src={require("../assets/top_chicken.webp")}
                    className="top_chicken"
                    data-aos="fade-up"
                  />
                </div>
                <a href="https://t.me/Screaming_base" target="blank">
                  {" "}
                  <div className="link_2">
                    <img src={require("../assets/telegram.webp")} />
                    <h3>Follow Us</h3>
                  </div>
                </a>
              </div>

              <div className="community_content">
                <p>
                  <span> Community competition</span> for telegram and twitter
                  subscribers. Members can
                  <br /> submit (DM ONLY) any themed photo, gif, cartoon, edit,
                  or video of a chicken (or
                  <br /> anything using the actual rubber chicken of course).
                  Entries will be shortlisted
                  <br /> & the top 3 funniest entries as voted by the community
                  will win $SCKN.
                </p>
                <p>
                  <span>* </span>Prize amounts of $SCKN will vary between 1st,
                  2nd & 3rd <span>*</span>
                </p>
                <p>
                  <span>* </span>Prize amounts of $SCKN will be announced and
                  set
                  <br />
                  the start of each new competition <span>*</span>
                </p>
                <p>
                  {" "}
                  <span>** </span>Competitions will be held randomly throughout
                  the year and announced on
                  <br />
                  TG & Twitter. Duration of each competition is random as well
                  so be quick! <span>**</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="chicken_effect">
          <img
            src={require("../assets/effect-2.webp")}
            data-aos="fade-left"
            className="effect"
          />
          <img
            src={require("../assets/chiken_end_effect.webp")}
            className="chicken_end"
          />
        </div>
        <section className="footer_chicken_section">
          <div className="container">
            <div className="footer_chicken_content">
              <img src={require("../assets/chicken_logo.png")} />
              <p className="w60">
                $SCKN is a meme coin created for entertainment purposes with the
                hope it can provide on-going resources to animals through
                charity
              </p>
              <p className="w60">
                $SCKN has been created for the community with no expectation of
                financial return. No formal development team or road map.
                Organic growth with potential for listings in the future
              </p>
            </div>
          </div>
        </section>
        <div className="copy_write_text">
          © 2024 by SCKN. All rights reserved!
        </div>
      </main>
    </>
  );
};

export default Landing;
